import "./Articles.scss";

import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import FormControl from "react-bootstrap/FormControl";
import Row from "react-bootstrap/Row";

import NavigationBar from "../../components/NavigationBar/NavigationBar";
import PageFooter from "../../components/PageFooter/PageFooter";

import Search from "../../assets/search.svg";

const ARTICLES = [
  {
    title: "Designing for a changing world",
    author: "Stephanie Szabó",
    date: "2021.07.21",
  },
];

const ARTICLE_CARD_GUTTER = <Col xs="1" className="d-none d-md-block" />;
const LINEBREAK = <div className="w-100" />;
const BEFORE_MD_LINEBREAK = <div className="w-100 d-md-none" />;

function getArticleCard(index: number): React.ReactElement {
  const { title, author, date } = ARTICLES[0];

  return (
    <>
      <Col className="ps-4 p-3 Articles__ArticleCard">
        <h2 className="fs-5 fw-bold">
          {title}
        </h2>
        <p className="mb-1 text-secondary fs-sm">{author}</p>
        <p className="mb-0 text-secondary fs-sm">{date}</p>
      </Col>
      {index % 2 === 0 && ARTICLE_CARD_GUTTER}
      {index % 2 === 0 && BEFORE_MD_LINEBREAK}
      {index % 2 === 1 && LINEBREAK}
    </>
  );
}

export default function Articles(): React.ReactElement {
  return (
    <div className="Articles d-flex flex-column">
      <NavigationBar />
      <Container className="bpy-1 bpy-sm-2 flex-grow-1">
        <Row>
          <Col>
            <h1 className="fs-4 fw-bold">
              Articles
            </h1>
            <p className="text-secondary">
              Discover our latest researches and releases
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="position-relative">
            <img src={Search} alt="search" className="Articles__SearchBarIcon" />
            <FormControl className="Articles__SearchBar" />
          </Col>
        </Row>
        <Row className="mt-3">
          {getArticleCard(0)}
          {getArticleCard(1)}
          {getArticleCard(2)}
          {getArticleCard(3)}
          {getArticleCard(4)}
          {getArticleCard(5)}
          {getArticleCard(6)}
          {getArticleCard(7)}
          {getArticleCard(8)}
          {getArticleCard(9)}
          {getArticleCard(10)}
          <Col />
        </Row>
      </Container>
      <PageFooter />
    </div>
  );
}
