import React from "react";

export default {
  departments: [
    {
      title: "Engineering",
      description: [""],
      roles: [
        {
          title: "Software Engineer - Remote",
          description: "Full-time / Remote",
          about: (
            <>
              <p>
                As a Software Engineer on the CKB Core Team, you`&apos;ll be at the
                forefront of driving innovation within the Nervos CKB ecosystem.
                This role presents an exciting opportunity to contribute to the
                design and implementation of cutting-edge features, ensuring the
                performance, scalability, and security of the core Nervos CKB.
                You&apos;ll have the chance to explore novel solutions that push the
                boundaries of blockchain technology, shaping the future
                evolution of the entire Nervos CKB ecosystem. We&apos;re seeking a
                talented and driven engineer who is passionate about making an
                impact in the world of blockchain technology. To get a better
                sense of what we&apos;re doing, check out the
                {" "}
                <a
                  href="https://github.com/nervosnetwork"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Nervos CKB GitHub repository
                </a>
                {" "}
                and watch this
                {" "}
                <a
                  href="https://www.youtube.com/watch?v=hIz8cB_YFWA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  introductory video on Nervos CKB
                </a>
                .
              </p>
              <p>
                For more insights into our work and culture, check out our
                {" "}
                <a
                  href="https://x.com/cryptape"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  team twitter
                </a>
                {" "}
                and our
                {" "}
                <a
                  href="https://blog.cryptape.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  latest blog posts
                </a>
                .
              </p>
            </>
          ),
          details: [
            {
              title: "Responsibilities",
              contents: [
                "Collaborate with the team to design and implement new features in the core Nervos CKB.",
                "Identify and troubleshoot performance issues to optimize the performance of Nervos CKB.",
                "Contribute to the development and improvement of the CKB Layer1 project, ensuring its efficiency and effectiveness.",
                "Explore emerging technologies and research areas to enhance the capabilities of the Nervos CKB ecosystem.",
              ],
            },
            {
              title: "Qualifications",
              contents: [
                "Bachelor's degree or above in a computer-related field.",
                "Proficient in at least one system language, such as Rust, C, or C++.",
                "Demonstrated experience with high-performance multi-threaded systems.",
                "Strong teamwork and communication skills, with the ability to collaborate effectively in a distributed team environment.",
              ],
            },
            {
              title: "Preferred Qualifications",
              contents: [
                "Previous involvement in open-source projects, demonstrating a commitment to collaborative development.",
                "Knowledge of cryptography and security protocols relevant to blockchain technology, enhancing the security of the Nervos CKB ecosystem.",
                "Experience in lower-level computer systems, computer architecture, or embedded systems, contributing to the optimization of Nervos CKB performance.",
                "Experience with projects involving handwritten assembly languages, providing insights into low-level optimization techniques.",
                "Previous experience as a maintainer of open-source libraries in esoteric languages, showcasing a diverse skill set and commitment to community-driven development.",
              ],
            },
            {
              title: "Perks",
              contents: [
                "100% remote, contribute from anywhere in the world",
                "Welcome package & gifts of holidays",
                "Leading parental leave policies",
                "Generous PTO / sick days",
                "Generous remote work stipends",
                "Professional development reimbursement",
                "Sponsored Team Building",
              ],
            },
          ],
        },
        {
          title: "Senior Blockchain Engineer - Remote",
          description: "Full-time / Remote",
          about: (
            <p>
              As a Senior Blockchain Engineer at Cryptape, you will be at the
              forefront of blockchain technology, working within the Nervos CKB
              ecosystem. Your expertise in blockchain, the UTXO model, and smart
              contracts will play a crucial role in developing and enhancing our
              blockchain solutions. This position offers the opportunity to work
              on groundbreaking projects, contribute to open-source initiatives,
              and collaborate with a team of experts in a dynamic and innovative
              environment.
            </p>
          ),
          details: [
            {
              title: "Responsibilities",
              contents: [
                "Design, develop, and maintain blockchain solutions with a focus on the UTXO model and smart contracts within the Nervos CKB ecosystem.",
                "Collaborate closely with the development team to integrate blockchain technologies into our existing and future projects.",
                "Optimize and enhance system performance by leveraging your deep understanding of operating systems and compilers, with a strong emphasis on Linux environments.",
                "Write high-quality, maintainable, and scalable code in C and Rust.",
                "Stay up-to-date with the latest developments in blockchain technology and actively contribute to the blockchain community through discussions, writings, and open-source projects.",
              ],
            },
            {
              title: "Qualifications",
              contents: [
                "Bachelor's degree or above in a computer-related field, with over 3 years of software development experience.",
                "Proven experience in blockchain technology, with a solid understanding of the UTXO model and smart contracts.",
                "Strong background in operating systems and compilers, with proficient use of Linux.",
                "Exceptional coding skills with a track record of writing efficient, secure, and clean code.",
                "Proficiency in C and Rust programming languages.",
                "Fluent in English, with strong writing and reading skills to effectively communicate and collaborate in an international setting.",
              ],
            },
            {
              title: "Preferred Qualifications",
              contents: [
                "Contributions to open-source blockchain projects.",
                "Experience in developing decentralized applications (DApps).",
                "Knowledge of cryptography and security protocols relevant to blockchain technology.",
                "Previous experience working in a fast-paced, tech startup environment.",
              ],
            },
            {
              title: "Perks",
              contents: [
                "100% remote, contribute from anywhere in the world",
                "Welcome package & gifts of holidays",
                "Leading parental leave policies",
                "Generous PTO / sick days",
                "Generous remote work stipends",
                "Professional development reimbursement",
                "Sponsored Team Building",
              ],
            },
          ],
        },
      ],
    },
  ],
};
