import React from "react";

import { Switch, Route, Redirect } from "react-router-dom";

import Articles from "./views/Articles/Articles";
import Home from "./views/Home/Home";
import JoinUs from "./views/JoinUs/JoinUs";

import JobRole from "./views/JobRole/JobRole";

export default function Routes(): React.ReactElement {
  return (
    <Switch>
      <Route path="/jobRole/:did/:jid">
        <JobRole />
      </Route>
      <Route path="/articles">
        <Articles />
      </Route>
      <Route path="/home">
        <Home />
      </Route>
      <Route path="/code">
        <Home />
      </Route>
      <Route path="/joinUs">
        <JoinUs />
      </Route>
      <Route path="/">
        <Redirect to="/home" />
      </Route>
    </Switch>
  );
}
