import "./Home.scss";

import React, { useEffect } from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useHistory, useLocation } from "react-router-dom";

import NavigationBar from "../../components/NavigationBar/NavigationBar";
import PageFooter from "../../components/PageFooter/PageFooter";

import ArrowRight from "../../assets/arrow-right.svg";

function ProjectCardLink(
  [key, { href, text }]: [number, { href: string, text: string}],
): React.ReactElement {
  return (
    <Col xs="auto" key={key}>
      <a href={href} className="fs-sm text-white text-decoration-none">
        {text}
      </a>
    </Col>
  );
}

const PROJECTS = [
  {
    title: "Nervos CKB",
    description: "Public Permissionless Blockchain",
    links: [
      { href: "https://www.nervos.org/", text: "Website" },
      { href: "https://github.com/nervosnetwork", text: "Code" },
    ],
  },
  {
    title: "Muta",
    description: "Highly Customizable High-performance Blockchain Framework",
    links: [
      { href: "https://github.com/nervosnetwork/muta", text: "Code" },
    ],
  },
  {
    title: "Ethereum",
    description: "Public Blockchain",
    links: [
      { href: "https://github.com/ethereum/simplecasper", text: "Simple Casper" },
      { href: "https://github.com/ethereum/casper", text: "Casper" },
      { href: "https://github.com/ethereum/pyethapp", text: "Pyethapp" },
      { href: "https://github.com/ethereum/Pydevp2p", text: "Pydevp2p" },
      { href: "https://github.com/ethereum/Pyethereum", text: "Pyethereum" },
      { href: "https://github.com/cryptape/ruby-ethereum", text: "Ruby Ethereum" },
    ],
  },
  {
    title: "CITA",
    description: "Hign Performance Blockchain Kernel for Enterprise Users",
    links: [
      { href: "https://www.citahub.com/#/", text: "Website" },
      { href: "https://github.com/citahub/cita", text: "Code" },
    ],
  },
  {
    title: "Neuron",
    description: "Blockchain Wallet",
    links: [
      { href: "https://github.com/nervosnetwork/neuron", text: "Code" },
    ],
  },
  {
    title: "CKB Explorer",
    description: "Blockchain Explorer",
    links: [
      { href: "https://explorer.nervos.org/", text: "Website" },
      { href: "https://github.com/nervosnetwork/ckb-explorer-frontend", text: "Frontend Code" },
      { href: "https://github.com/nervosnetwork/ckb-explorer", text: "Backend Code" },
    ],
  },
  {
    title: "Spark Pool",
    description: "The Largest Ethereum Mining Pool",
    links: [
      { href: "https://www.sparkpool.com/", text: "Website" },
    ],
  },
  {
    title: "Peatio",
    description: "Crypto Trading Platform",
    links: [
      { href: "https://www.peatio.tech/", text: "Code" },
    ],
  },
];

function ProjectCard(index: number): React.ReactElement {
  const { title, description, links } = PROJECTS[index];

  return (
    <Col
      key={index}
      xs="12"
      md="6"
      lg="4"
      className="p-2"
    >
      <div className="text-white Home__ProjectCard p-4 d-flex flex-column justify-content-between">
        <Row className="fs-sm mx-0">
          <Col xs="12" className="fw-bold">{String(index + 1).padStart(2, "0")}</Col>
          <Col xs="12"><h1 className="fw-bold fs-4 my-1 p-0">{title}</h1></Col>
          <Col xs="12" className="text-dark-secondary">{description}</Col>
        </Row>
        <Row className="mx-0">
          {Array.from(links.entries(), ProjectCardLink)}
        </Row>
      </div>
    </Col>
  );
}

const TITLE = (
  <h1 className="fs-4 fw-bold">
    &quot;Talk is cheap.
    <br />
    Show me the code.&quot;
    <br />
    ― Linus Torvalds
  </h1>
);
const CONTENT = (
  <>
    <p>
      We are passionate engineers who transform visions into reality.
      <br />
      We build trust with blockchain technology.
      <br />
      We are the architects of the future.
    </p>
    <p>
      Cryptape is a veteran in blockchain infrastructure research and
      engineering. By committing to the &quot;open source everything&quot; model,
      Cryptape continues to design and build trustworthy protocols and
      products serving millions of users, covering all aspects of the
      blockchain ecosystem and servicing the digital world of tomorrow.
    </p>
  </>
);

export default function Home(): React.ReactElement {
  const url = `${window.location.origin}/3dape/`;
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const scrollChange = () => {
      if (location.pathname === "/code" && document.documentElement.scrollTop === 0) {
        history.replace("/home");
      }
      if (location.pathname === "/home" && document.getElementsByClassName("Home__Projects")[0].getBoundingClientRect().top <= 0) {
        history.replace("/code");
      }
    };
    if (location.pathname === "/home") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (location.pathname === "/code") {
      document.getElementsByClassName("Home__Projects")[0].scrollIntoView({ behavior: "smooth" });
    }
    window.addEventListener("scroll", scrollChange);
    return () => window.removeEventListener("scroll", scrollChange);
  }, [history, location.pathname]);

  return (
    <div className="Home">
      <NavigationBar />
      <a href={url}>
        <button type="button" className="Home__Mirror d-none d-sm-block" aria-label="Go to 3D Ape"> </button>
      </a>
      <div className="Home__Poster d-none d-sm-block" />
      <div className="Home__Gap d-none d-sm-block"> </div>
      <a href={url}>
        <button type="button" className="Home__Poster__Xs w-100 d-sm-none" aria-label="Go to 3D Ape"> </button>
      </a>
      <Container>
        <Row className="bpy-1 bpy-sm-2 justify-content-center">
          <Col xs="12" className="d-lg-none">
            {TITLE}
          </Col>
          <Col xs="12" className="text-secondary d-lg-none mt-3">
            {CONTENT}
          </Col>
          <div
            className="d-none d-lg-block px-0"
            style={{ width: "240px", marginRight: "16px" }}
          >
            {TITLE}
          </div>
          <div
            className="text-secondary d-none d-lg-block px-0"
            style={{ width: "600px" }}
          >
            {CONTENT}
          </div>
        </Row>
      </Container>
      <div className="Home__Projects bpy-1 bpy-sm-2">
        <Container>
          <Row className="align-items-stretch">
            {Array.from(PROJECTS.keys(), ProjectCard)}
            <Col
              className="
                text-white
                fw-bold
                fs-5
                p-4
                d-flex
                align-items-center
                align-items-lg-start
                justify-content-end
                justify-content-md-center
                justify-content-lg-start
              "
            >
              <div className="d-flex align-items-center">
                <a href="https://github.com/cryptape" className="text-decoration-none text-white" aria-label="More">
                  More
                  <img src={ArrowRight} alt="arrow right" width="17" height="17" className="ms-1" />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <PageFooter />
    </div>
  );
}
