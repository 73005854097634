import "./JobRole.scss";

import React from "react";
import { useParams } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import NavigationBar from "../../components/NavigationBar/NavigationBar";
import PageFooter from "../../components/PageFooter/PageFooter";

import jobs from "../../utils/jobs";

type jobDetail = {
  title: string,
  contents: string[]
}
function getRoleContentElements(content: jobDetail[]): React.ReactElement[] {
  const contentElements: React.ReactElement[] = [];

  content.forEach(({ title, contents }) => {
    contentElements.push(
      <h2 key={title} className="fs-5 fw-bold mb-3">{title}</h2>,
    );
    contentElements.push(
      <ul key={Math.random()} className="pb-4">
        {
          contents.map((text: string) => <li key={Math.random()}>{text}</li>)
        }
      </ul>,
    );
  });

  return contentElements;
}

export default function JobRole(): React.ReactElement {
  const { did, jid } = useParams<{ did: string, jid: string }>();
  const departmentId = Number(did);
  const jobId = Number(jid);
  const department = jobs.departments[departmentId];
  const job = department.roles[jobId];

  return (
    <div className="JobRole">
      <NavigationBar />
      <div className="JobRole__Text bpy-1 bpy-sm-2">
        <Container>
          <Row className="JobRole__Content">
            <Col xs="12">
              <h1 className="fs-4 fw-bold">
                {job.title}
              </h1>
            </Col>
            <Col xs="12" className="text-secondary">
              <p>{job.description}</p>
            </Col>
            <Col xs="12" className="text-secondary">
              {job.about}
            </Col>
            {
              department.description.map((item) => (
                <Col key={item} xs="12" className="text-secondary">
                  <p>{item}</p>
                </Col>
              ))
            }
          </Row>
        </Container>
      </div>
      <Container className="bpy-1 bpy-sm-2">
        <Row className="JobRole__Content">
          <Col className="py-4 pt-md-0">
            {getRoleContentElements(job.details)}
            <h2 key={Math.random()} className="fs-5 fw-bold mb-3">
              Sounds like a good fit? Email us at&nbsp;
              <a href={`mailto:join@cryptape.com?subject=[${job.title}]-YourName&body=Send along links that best showcase the relevant things you've built and done.`}>
                join@cryptape.com
              </a>
            </h2>
            <ul key={Math.random()} className="pb-4">
              <li key="Make sure to include the job title in the subject line,">Make sure to include the job title in the subject line,</li>
              <li key="and attach any relevant links that best showcase your work.">and attach any relevant links that best showcase your work.</li>
            </ul>
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col xs="auto">
            <a href={`mailto:join@cryptape.com?subject=[${job.title}]-YourName&body=Send along links that best showcase the relevant things you've built and done.`}>
              <button type="button" className="JobRole__JoinButton">
                Apply
              </button>
            </a>
          </Col>
        </Row>
      </Container>
      <PageFooter />
    </div>
  );
}
