import "./App.scss";

import React from "react";

import { HashRouter } from "react-router-dom";

import Routes from "./Routes";

export default function App(): React.ReactElement {
  return (
    <HashRouter>
      <Routes />
    </HashRouter>
  );
}
