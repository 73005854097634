import "./JoinUs.scss";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Accordion from "react-bootstrap/Accordion";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import NavigationBar from "../../components/NavigationBar/NavigationBar";
import PageFooter from "../../components/PageFooter/PageFooter";

import ArrowRight from "../../assets/arrow-right-dark.svg";

import jobs from "../../utils/jobs";

const DEPARTMENTS = Array.from(jobs.departments, (job) => `${job.title}`);

function getDepartmentItem(
  index: number,
  setter: (_: number) => void,
  selectedIndex: number,
  isHorizontal: boolean,
): React.ReactElement | null {
  if (selectedIndex === index && !isHorizontal) {
    return null;
  }

  return (
    <div
      key={index}
      className={`
        JoinUs__Department
        ${selectedIndex === index ? "fw-bold" : ""}
        ${isHorizontal ? "px-0" : ""}
      `}
      onClick={() => setter(index)}
      onKeyPress={() => setter(index)}
      role="button"
      tabIndex={0}
    >
      {DEPARTMENTS[index]}
    </div>
  );
}

function DepartmentSelect(isHorizontal: boolean, setTag: (_: number) => void):
  React.ReactElement {
  const [selectedDepartment, setSelectedDepartment] = useState(0);
  const [activeKey, setActiveKey] = useState<string>("null");

  function switchSelectedDepartment(index: number): void {
    setSelectedDepartment(index);
    setActiveKey("null");
    setTag(index);
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  if (isHorizontal) {
    return (
      <>
        {
          Array.from(
            DEPARTMENTS.keys(),
            (index) => getDepartmentItem(
              index,
              switchSelectedDepartment,
              selectedDepartment,
              isHorizontal,
            ),
          )
        }
      </>
    );
  }

  return (
    <Accordion
      activeKey={activeKey}
      flush
      className="JoinUs__Departments"
      onSelect={(key) => {
        if (Array.isArray(key)) {
          setActiveKey(key.join(", "));
        } else {
          setActiveKey(key || "null");
        }
      }}

    >
      <Accordion.Item eventKey="0" style={{ background: "none" }}>
        <Accordion.Header className="JoinUs__SelectedDepartment">
          <span className="fw-bold">{DEPARTMENTS[selectedDepartment]}</span>
        </Accordion.Header>
        <Accordion.Body className="p-0">
          {
            Array.from(
              Array.from(DEPARTMENTS.keys()).filter((index) => index !== selectedDepartment),
              (index) => getDepartmentItem(
                index,
                switchSelectedDepartment,
                selectedDepartment,
                isHorizontal,
              ),
            )
          }
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default function JoinUs(): React.ReactElement {
  const [jobTag, setJobTag] = useState(0);
  const [jobList, setJobList] = useState(jobs.departments[0].roles);

  function switchSelectedJobTag(index: number): void {
    setJobTag(index);
  }

  function getRoleCard(index: number): React.ReactElement {
    const { title, description } = jobList[index];
    const jobRoleLink = `/jobRole/${jobTag}/${index}`;
    return (
      <Row
        key={index}
        className="align-items-center JoinUs__Role p-3 mx-0 position-relative"
      >
        <Col>
          <Row><h3 className="fs-4 fw-bold p-0">{title}</h3></Row>
          <Row className="fs-sm text-secondary">{description}</Row>
        </Col>
        <Col xs="auto" className="p-0">
          <Link to={jobRoleLink} className="stretched-link">
            <img src={ArrowRight} alt="arrow right" style={{ height: "1.62rem" }} />
          </Link>
        </Col>
      </Row>
    );
  }

  useEffect(() => {
    const list = jobs.departments[jobTag].roles;
    setJobList(list);
  }, [jobTag]);

  return (
    <div className="JoinUs">
      <NavigationBar />
      <div className="JoinUs__Poster" />
      <div className="JoinUs__Text bpy-1 bpy-sm-2">
        <Container>
          <Row>
            <Col xs="12">
              <h1 className="fs-4 fw-bold">
                Join Cryptape
              </h1>
            </Col>
            <Col xs="12" className="mt-3 text-secondary">
              <p>
                Blockchain is not merely about cryptocurrency. At Cryptape, we believe in
                creating trust with blockchain technology. We commit to building reliable
                infrastructures that replace any reliance on trusted third parties. We aim
                to grant everyone true ownership of their resources. We seek to empower
                passionate individuals who share our core mission.
              </p>
              <p>
                Cryptape is a technology-driven company built by engineers for engineers.
                Our fully distributed but connected team values everyone’s input, encourages
                knowledge sharing, and rewards innovation. We independently design protocols
                and open source everything we create. Together, we create a permissionless
                and trustless infrastructure for the digital economy.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="bpy-1 bpy-sm-2">
        <Row>
          <Col xs="12" md="4" lg="3">
            <div
              className="
                split-border-bottom
                split-border-bottom-md-0
                split-border-end-md
                pb-4
                pb-md-0
                h-100
              "
            >
              <h2 className="fs-sm fw-bold text-uppercase">Departments</h2>
              <div className="d-md-none">{DepartmentSelect(false, switchSelectedJobTag)}</div>
              <div className="d-none d-md-block">{DepartmentSelect(true, switchSelectedJobTag)}</div>
            </div>
          </Col>
          <Col className="py-4 pt-md-0 px-md-4">
            <Row className="mx-0 mb-2"><h2 className="fs-sm fw-bold text-uppercase p-0">Roles</h2></Row>
            {Array.from(jobList.keys(), getRoleCard)}
          </Col>
        </Row>
      </Container>
      <PageFooter />
    </div>
  );
}
