import "./NavigationBar.scss";

import React, { useEffect, useRef, useState } from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import NavDropdown from "react-bootstrap/NavDropdown";

import {
  Link, NavLink, useHistory, useLocation,
} from "react-router-dom";

import Logo from "../../assets/logo.png";

const LINKS = [
  { text: "Code", to: "/code" },
  { text: "Join Us", to: "/joinUs" },
];

function useLink(index: number): React.ReactElement {
  const { to, text } = LINKS[index];
  const location = useLocation();
  return (
    <NavLink
      className="
        NavigationBar__Link
        text-decoration-none
        text-black
        d-flex
        align-items-center
        justify-content-center
        p-2
        mx-3
      "
      to={to}
      onClick={() => {
        if (location.pathname === "/code" && to === "/code"
          && document.getElementsByClassName("Home__Projects")[0].getBoundingClientRect().top > 0) {
          document.getElementsByClassName("Home__Projects")[0].scrollIntoView({ behavior: "smooth" });
        }
      }}
    >
      {text}
    </NavLink>
  );
}

export default function NavigationBar(): React.ReactElement {
  const [placeholderHeight, setPlaceholderHeight] = useState(0);
  const navBarRef = useRef<HTMLElement>(null);
  const location = useLocation();
  const history = useHistory();

  function tweakPlaceholder() {
    if (navBarRef.current) {
      setPlaceholderHeight(navBarRef.current.clientHeight);
    }
  }

  useEffect(
    () => {
      window.addEventListener("resize", tweakPlaceholder);
      tweakPlaceholder();
      return () => window.removeEventListener("resize", tweakPlaceholder);
    },
    [navBarRef],
  );
  return (
    <>
      <div className="w-100" style={{ height: `${placeholderHeight}px` }} />
      <Navbar fixed="top" className="shadow p-0 NavigationBar" ref={navBarRef}>
        <Container>
          <Row className="w-100 justify-content-between mx-0">
            <Col xs="12" sm="auto" className="my-3 mb-2 mb-sm-3 d-flex justify-content-center">
              <Navbar.Brand>
                <Link
                  to="/home"
                  onClick={(e) => {
                    if (location.pathname === "/home") {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    } else if (location.pathname === "/code") {
                      e.preventDefault();
                      window.scrollTo({ top: 0, behavior: "smooth" });
                      setTimeout(() => history.replace("/home"), 600);
                    }
                  }}
                >
                  <img src={Logo} width="112" height="32" alt="Cryptape" />
                </Link>
              </Navbar.Brand>
            </Col>
            <Col xs="12" sm className="d-flex align-items-stratch justify-content-center justify-content-sm-end">
              {useLink(0)}
              <a className="NavigationBar__Link text-decoration-none text-black d-flex align-items-center justify-content-center p-2 mx-3" href="https://blog.cryptape.com/" target="_blank" rel="noreferrer">Blog</a>
              {useLink(1)}
              <NavDropdown title="Connect">
                <NavDropdown.Item href="https://twitter.com/cryptape">Twitter</NavDropdown.Item>
                <NavDropdown.Item href="https://www.youtube.com/@Cryptape">YouTube</NavDropdown.Item>
              </NavDropdown>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </>
  );
}
