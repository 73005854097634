import "./PageFooter.scss";

import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

export default function PageFooter(): React.ReactElement {
  return (
    <div className="PageFooter">
      <Container>
        <Row className="justify-content-between">
          <Col xs="12" lg="auto">
            <Row className="justify-content-center">
              <Col xs="auto">
                <p className="text-white mb-0">We Build Trust</p>
              </Col>
              <Col xs="auto">
                <a href="https://github.com/cryptape">GitHub</a>
              </Col>
              <Col xs="auto">
                <a href="mailto:contact@cryptape.com">Contact Us</a>
              </Col>
            </Row>
          </Col>
          <Col
            xs="12"
            lg
            className="d-flex align-items-center justify-content-center justify-content-lg-end mt-2 mt-lg-0"
          >
            <Row className="text-secondary justify-content-center fs-sm">
              <div className="w-100 d-lg-none" />
              <Col xs="auto" className="p-0 ps-1">
                ©2016-2023
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
